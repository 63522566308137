import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "visios"
let collectionName = collectionPrefix + collectionId

class Visio{
    constructor(id, name, room, isPrivate = false, users = []){
        this.id = id
        this.name = name
        this.room = room
        this.isPrivate = isPrivate
        this.users = users
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let visios = []

        response.forEach(document => {
            let data = document.data()
            let tmp_visios = new Visio(document.id, data.name, data.room, data.isPrivate, data.users)
            visios.push(tmp_visios)
        })

        return visios
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let visio = new Visio(response.id, data.name, data.room, data.isPrivate, data.users)

        return visio
    }

    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        let response = await getDocs(documentsQuery)

        let visios = []

        response.forEach(document => {
            let data = document.data()
            let tmp_visio = new Visio(document.id, data.name, data.room, data.isPrivate, data.users)
            visios.push(tmp_visio)
        })
        return visios
    }

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let visios = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_visio = new Visio(document.id, data.name, data.room, data.isPrivate, data.users)
                visios.push(tmp_visio)
            })
            
            if(callback != null){
                callback(visios)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let visio = new Visio(snapshot.id, data.name, data.room, data.isPrivate, data.users)
            
            if(callback != null){
                callback(visio)
            }
        })
        return unsub
    }

    static listenByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let visios = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_visio = new Visio(document.id, data.name, data.room, data.isPrivate, data.users)
                visios.push(tmp_visio)
            })
            
            if(callback != null){
                callback(visios)
            }
        })
        return unsub
    }

    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        if(this.id == null){
            let collectionRef = collection(db, collectionName)
            let response = await addDoc(collectionRef, {
                name: this.name,
                room: this.room,
                isPrivate: this.isPrivate,
                users: this.users,
            })
            this.id = response.id
        }else{
            let documentRef = doc(db, collectionName, this.id)
            await updateDoc(documentRef, {
                name: this.name,
                room: this.room,
                isPrivate: this.isPrivate,
                users: this.users,
            })
        }
    }

    async delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Visio