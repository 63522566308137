<template>
	<div v-if="new Array('User', 'Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
		<v-card class="my-3">
            <v-card-title>Salles de visioconférence</v-card-title>
			<v-card-text>
                <v-row class="pt-3">
                    <v-col cols="12" v-if="visios && visios.length == 0">
                        <h3>Aucune salle de visioconférence n'est disponible pour le moment.</h3>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" v-else v-for="visio in visios">
                        <v-hover v-slot="{hover}">
                            <v-sheet rounded :elevation="hover ? 4 : 2" :color="hover ? 'lighter' : 'unset'" class="pa-3 pointer d-flex align-center justify-space-between" @click="openVisio(visio)">
                                <span class="text-subtitle-1">
                                    <v-icon class="pa-3" color="warning" v-if="visio.isPrivate">mdi-lock-outline</v-icon>
                                    <v-icon class="pa-3" color="primary" v-else>mdi-lock-open-variant-outline</v-icon>
                                    <span v-text="visio.name"></span>
                                </span>
                                <span class="text-subtitle-1 d-flex align-center justify-space-between" v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
                                    <v-btn color="secondary" icon @click.prevent.stop="openModal(visio)">
                                        <v-icon color="secondary">mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    <v-btn color="error" icon @click.prevent.stop="deleteVisio(visio)">
                                        <v-icon color="error">mdi-delete-outline</v-icon>
                                    </v-btn>
                                </span>
                            </v-sheet>
                        </v-hover>
                    </v-col>
                </v-row>
                <span class="pt-5 pa-3 d-flex justify-end" v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
                    <v-btn color="primary" fab @click="openModal(null)">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </span>
			</v-card-text>
		</v-card>
        <v-dialog v-model="modalDisplay" permanent max-width="1000px">
			<v-card v-if="modalData">
				<v-card-title>
					<v-text-field autofocus label="Nom de la visio" class="px-2" v-model="modalData.name" />
				</v-card-title>
				<v-card-text>
                    <v-row>
                        <v-col cols="12" class="d-flex align-center justify-center">
                            <span class="pr-2 ">Public</span>
                            <v-switch color="warning" v-model="modalData.isPrivate"></v-switch>
                            <span class="pl-2 ">Privé</span>
                        </v-col>
                        <v-col cols="12" class="d-flex align-center justify-center" v-if="modalData.isPrivate">
                            <v-select :items="profiles" multiple label="Utilisateurs" item-value="id" chips v-model="modalData.users">
                                <template slot="item" slot-scope="data">
                                    <UserCard :profile="data.item" class="py-5"></UserCard>
                                </template>
                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index < 3">
                                        <span v-text="item.lastname + ' ' + item.firstname"></span>
                                        <v-btn x-small text @click.prevent.stop="modalData.users = modalData.users.filter(userID => userID != item.id)">X</v-btn>
                                    </v-chip>
                                    <span v-if="index == 3" class="grey--text text-caption" v-text=" '( +'+ (modalData.users.length - 3) +' autres )' "></span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" outlined @click="closeModal"> Annuler </v-btn>
					<v-btn color="primary" text @click="saveModal"> sauvegarder </v-btn>
				</v-card-actions>
			</v-card>
        </v-dialog>
        
        <v-dialog v-model="visioDisplay" permanent max-width="80vw" @click:outside="closeVisio" scrollable>
            <v-card style="height:80vh;width:80vw">
                <div id="meet" style="height:100%;width:100%;overflow: hidden;"></div>
            </v-card>
        </v-dialog>
	</div>
</template>

<style scoped>
    * >>> .v-list-item,* >>> .v-select__selections{
        height:5rem !important;
    }
</style>

<script>
    import Swal from 'sweetalert2/dist/sweetalert2.js'

    import Visio from '@/classes/Visio'
    import Profile from '@/classes/Profile'

    import UserCard from '@/components/UserCard'

	export default {
		name: "Visio",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
        components: {
            UserCard
        },
		data() {
			return {
                meetApi: null,
                visios: [],
                modalDisplay : false,
                modalData : null,
                visioDisplay : false,
                visioUrl : null,
                profiles: [],
                unsub : [],
			}
		},
        created() {
            this.unsub.push(Profile.listenByRoom(this.profile.currentRoom, (profiles) => {
                    this.profiles = profiles
                }
            ))
            
            if(this.profile.role == 'User') {
                this.unsub.push(Visio.listenByRoom(this.profile.currentRoom, (visioResponse) => {
                    let tmp_visios = []
                    for(let visio of visioResponse){
                        if(!visio.isPrivate || visio.users.includes(this.profile.id)) {
                            tmp_visios.push(visio)
                        }
                    }
                    this.visios = tmp_visios
                }))
                
            }else if(this.profile.role != 'None'){
                this.unsub.push(Visio.listenByRoom(this.profile.currentRoom, (visioResponse) => {
                    this.visios = visioResponse
                }))
            }else{
                this.visios = []
            }
        },
        methods: {
            openVisio(visio) {
                this.visioUrl = 'valy_' + visio.name + '_' + new Date().toDateString() + '_' + visio.id//'https://meet.jit.si/valy_' + visio.name + '_' + new Date().toDateString() + '_' + visio.id
                this.visioDisplay = true
                setTimeout(this.initMeetApi, 100);
            },
            closeVisio() {
                this.visioUrl = null
                this.disposeMeetApi()
                this.visioDisplay = false
            },
            openModal(data){
                if(data){
                    this.modalData = {
                        id : data.id,
                        name : data.name,
                        isPrivate : data.isPrivate,
                        users : data.users,
                    }
                }else{
                    this.modalData = {
                        id : null,
                        name : '',
                        isPrivate : false,
                        users : [],
                    }
                }
                this.modalDisplay = true
            },
            closeModal(){
                this.modalData = null
                this.modalDisplay = false
            },
            saveModal(){
                let tmp_visio = new Visio(this.modalData.id, this.modalData.name, this.profile.currentRoom, this.modalData.isPrivate, this.modalData.users)
                tmp_visio.save()
                this.closeModal()
            },
            deleteVisio(visio){
                Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if(result.isConfirmed){
                        visio.delete()
					}
				})
            },
            initMeetApi(){
                let domain = 'meet.jit.si'
                let options = {
                    roomName: this.visioUrl,
                    parentNode: document.querySelector('#meet'),
                    lang: 'fr',
                    configOverwrite: {
                        toolbarButtons: ['camera', 'chat', 'closedcaptions', 'desktop', 'download', 'etherpad', 'fullscreen', 'hangup', 'microphone', 'noisesuppression', 'participants-pane', 'profile', 'settings', 'tileview', 'toggle-camera', 'videoquality'],
                        gravatar:{
                            disabled: true
                        },
                        prejoinConfig: {
                            enabled: false,
                        },
                        startWithAudioMuted: true,
                        startWithVideoMuted: true,
                    },
                    userInfo: {
                        email: this.profile.email,
                        displayName: this.profile.firstname + " " + this.profile.lastname,
                    }
                }
                this.meetApi = new JitsiMeetExternalAPI(domain, options)
                this.meetApi.addListener('videoConferenceLeft', () => {
                    this.disposeMeetApi()
                })
            },
            disposeMeetApi(){
                if(this.meetApi){
                    this.visioDisplay = false
                    this.meetApi.dispose()
                }
            },
        },
        destroyed() {
            this.unsub.forEach((unsub) => unsub())
        },
	}
</script>
